<template>
  <AuthLayout>
    <template v-slot:authContent>
      <!-- reset password start -->
      <section class="row flexbox-container">
        <div class="col-xl-7 col-10">
          <div class="card bg-authentication mb-0">
            <div class="row m-0">
              <!-- left section-login -->
              <div class="col-md-6 col-12 px-0">
                <div class="card disable-rounded-right d-flex justify-content-center mb-0 p-2 h-100">
                  <div class="card-header pb-1">
                    <div class="card-title">
                      <h4 class="text-center mb-2">Reset your Password</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <form class="mb-2">
                      <div class="form-group">
                        <label class="text-bold-600" for="password">Password</label>
                        <input v-model="postResetPasswordData.password" type="password" class="form-control"
                               id="password" placeholder="Enter a new password">
                        <div class="text-danger" v-if="errors.username">{{ errors.username }}</div>
                        <div class="text-danger" v-if="errors.password">{{ errors.password }}</div>
                      </div>
                      <div class="form-group mb-2">
                        <label class="text-bold-600" for="passwordConfirmation">New Password</label>
                        <input v-model="postResetPasswordData.password_confirmation" type="password"
                               class="form-control" id="passwordConfirmation" placeholder="Confirm your new password">
                        <div class="text-danger" v-if="errors.passwordConfirmation">
                          {{errors.passwordConfirmation }}
                        </div>
                      </div>
                      <button @click="submitResetPassword" type="button"
                              class="btn btn-primary glow position-relative w-100">
                        Reset my password<i id="icon-arrow" class="bx bx-right-arrow-alt"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <!-- right section image -->
              <div class="col-md-6 d-md-block d-none text-center align-self-center p-3">
                <img class="img-fluid" :src="resetPasswordPageImage" alt="reset-password-page-image">
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- reset password ends -->
    </template>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/layouts/backEnd/AuthLayout.vue'
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {mapActions} from "vuex";

export default {
  name: "ResetPassword",


  mixins: [ShowToastMessage, Loader],

  components: {
    AuthLayout
  },

  props: ['username'],

  data() {
    return {
      resetPasswordPageImage: '/app-assets/images/pages/reset-password.png',
      postResetPasswordData: {
        username: this.username,
        password: '',
        password_confirmation: '',
      },

      errors: {
        username: '',
        password: '',
        passwordConfirmation: '',
      }
    }
  },

  methods: {
    ...mapActions({
      putResetPassword: 'appResetPassword/putResetPassword',
    }),

    async submitResetPassword() {
      await this.putResetPassword(this.postResetPasswordData).then(async (response) => {
        if (response.status === 200) {
          await this.$router.replace({
            name: 'appLogin',
          });
        }

        this.errors.username = response.errors && response.errors.username ? response.errors.username[0] : '';
        this.errors.password = response.errors && response.errors.password ? response.errors.password[0] : '';
        this.errors.passwordConfirmation = response.errors && response.errors.passwordConfirmation ? response.errors.passwordConfirmation[0] : '';

        if (this.errors.username) {
          await this.$router.replace( {
            name: 'appForgotPassword',
            params: {
              initToastDataMessage: 'Invalid Username.',
              initToastDataType: 'error',
            },
          });
        }

        if (response.message) {
          this.showToastMessage(response);
        }

      })
    },
  },

  async mounted() {
    if (!this.username) {
      await this.$router.replace({
        name: 'appForgotPassword',
        params: {
          initToastDataMessage: 'Invalid Username.',
          initToastDataType: 'error',
        },
      });
    }
  }
}
</script>

<style>

</style>
